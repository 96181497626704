import { defineStore } from 'pinia';
import { ref, watch, onMounted } from '@vue/composition-api';
import ls from '../utils/localStorage';
import { Product } from '@vf/api-client';

type CompareProduct = {
  id: string;
  title: string;
  image: {
    small: string;
    medium?: string;
    large?: string;
  };
  link: string;
  regularPrice: string;
  specialPrice: string;
  details?: Product;
  fetchedDetails?: boolean;
};

export const useCompareStore = defineStore('compare', () => {
  const products = ref<CompareProduct[]>([]);

  onMounted(() => {
    const storedProducts = ls.getItem('compareProducts');
    if (storedProducts) {
      products.value = JSON.parse(storedProducts);
    }
  });

  watch(
    products,
    (newProducts) => ls.setItem('compareProducts', JSON.stringify(newProducts)),
    { deep: true }
  );

  return {
    products,
  };
});
