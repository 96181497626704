

























































































































































































import { ref, computed, defineComponent } from '@vue/composition-api';
import { useCompareStore } from '@vf/composables/src/store/compare';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import useRootInstance from '@/shared/useRootInstance';
import { useProduct } from '@vf/composables';
import useLoader from '@/shared/useLoader';

const COMPARE_FEATURES = [
  {
    name: 'material',
    prefix: 'Materials|',
  },
  {
    name: 'fit',
    prefix: 'Fit/Style|',
  },
  {
    name: 'feel',
    prefix: 'Feel|',
  },
] as const;

const FLEECE_PRODUCT_IDS = [
  'NF0A88XJ',
  'NF0A832U',
  'NF0A84F1',
  'NF0A88XK',
  'NF0A5J7S',
  'NF0A5J8R',
  'NF0A7WYA',
  'NF0A7UJJ',
  'NF0A8A1E',
  'NF0A8AGY',
  'NF0A7USO',
  'NF0A87RK',
  'NF0A87RN',
  'NF0A88YS',
  'NF0A84LD',
  'NF0A7WYB',
  'NF0A84F5',
  'NF0A84JJ',
  'NF0A84JL',
  'NF0A8AYN',
  'NF0A84JI',
  'NF0A8932',
  'NF0A8AYR',
  'NF0A5J8T',
  'NF0A5J8C',
  'NF0A7UV7',
];

export default defineComponent({
  name: 'Compare',
  setup() {
    const { root } = useRootInstance();
    const { showSpinner, hideSpinner } = useLoader();

    const { getProductDetails } = useProduct(root);
    const { pageTypeName } = storeToRefs(useCmsRefStore());
    const { products } = storeToRefs(useCompareStore());

    const isModalVisible = ref(false);
    const isProductDetailsLoading = ref(false);

    const openModal = async () => {
      isModalVisible.value = true;

      const productsNeedingDetails = products.value.filter(
        ({ fetchedDetails }) => !fetchedDetails
      );
      if (!productsNeedingDetails.length) return;

      isProductDetailsLoading.value = true;
      showSpinner();

      try {
        const updatedProducts = await Promise.all(
          productsNeedingDetails.map(async (product) => {
            const details = await getProductDetails(product.id);

            return {
              ...product,
              fetchedDetails: true,
              weight: getBenefitRating(details.weightRating),
              ...COMPARE_FEATURES.reduce(
                (result, { name, prefix }) => ({
                  ...result,
                  [name]: details.features
                    ?.filter((detail) => detail.startsWith(prefix))
                    .map((detail) => detail.split('|')[1]),
                }),
                {} as Record<typeof COMPARE_FEATURES[number]['name'], string[]>
              ),
            };
          })
        );

        products.value = products.value.map(
          (p) => updatedProducts.find((up) => up.id === p.id) || p
        );
      } catch (error) {
        console.error('Failed to fetch product details:', error);
      } finally {
        isProductDetailsLoading.value = false;
        hideSpinner();
      }
    };

    const getBenefitRating = (ratingString: string | undefined) => {
      if (!ratingString) return null;
      const [index, ...rankings] = ratingString.split('|');
      return rankings[+index - 1];
    };

    const closeModal = () => {
      isModalVisible.value = false;
    };

    const remove = (productId: string) => {
      products.value = products.value.filter(({ id }) => id !== productId);
      if (products.value.length === 0) {
        closeModal();
      }
    };

    const showCompare = computed(() => {
      const currentPath = root.$route.path as string;

      const hasProducts = products.value.length > 0;
      const isRelevantPageType = [PageTypeName.PDP, PageTypeName.PLP].includes(
        pageTypeName.value
      );
      const isRelevantRoute =
        currentPath.includes('mens-fleece') ||
        FLEECE_PRODUCT_IDS.some((id) => currentPath.includes(id));

      return hasProducts && isRelevantPageType && isRelevantRoute;
    });

    const compareCategories = [
      { label: 'Material/Fabric', key: 'material' },
      { label: 'Fit/Sizing', key: 'fit' },
      { label: 'How it feels', key: 'feel' },
      { label: 'Weight', key: 'weight' },
    ];

    return {
      showCompare,
      isModalVisible,
      products,
      isProductDetailsLoading,
      remove,
      openModal,
      closeModal,
      pageTypeName,
      PageTypeName,
      compareCategories,
    };
  },
});
