import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_573e30f8 from 'nuxt_plugin_plugin_573e30f8' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_plugin_50c80fe0 from 'nuxt_plugin_plugin_50c80fe0' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_pluginutils_11babbed from 'nuxt_plugin_pluginutils_11babbed' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_43fc01f8 from 'nuxt_plugin_pluginrouting_43fc01f8' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7b04ec77 from 'nuxt_plugin_pluginmain_7b04ec77' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_nuxtenv_726e3a87 from 'nuxt_plugin_nuxtenv_726e3a87' // Source: ./nuxt-env.js (mode: 'all')
import nuxt_plugin_workbox_65968cac from 'nuxt_plugin_workbox_65968cac' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_0436b6bd from 'nuxt_plugin_metaplugin_0436b6bd' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_gtm_08698264 from 'nuxt_plugin_gtm_08698264' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_axios_15afdfe4 from 'nuxt_plugin_axios_15afdfe4' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_stringprototypereplaceall_73e5df84 from 'nuxt_plugin_stringprototypereplaceall_73e5df84' // Source: ./nuxt-polyfill/string.prototype.replaceall.js (mode: 'all')
import nuxt_plugin_smoothscrollpolyfill_1951eb79 from 'nuxt_plugin_smoothscrollpolyfill_1951eb79' // Source: ./nuxt-polyfill/smoothscroll-polyfill.js (mode: 'all')
import nuxt_plugin_intersectionobserver_b7e87bc0 from 'nuxt_plugin_intersectionobserver_b7e87bc0' // Source: ./nuxt-polyfill/intersection-observer.js (mode: 'all')
import nuxt_plugin_resizeobserver_69bc4a0b from 'nuxt_plugin_resizeobserver_69bc4a0b' // Source: ./nuxt-polyfill/resize-observer.js (mode: 'all')
import nuxt_plugin_distplugin57527824_68708b8a from 'nuxt_plugin_distplugin57527824_68708b8a' // Source: ./dist.plugin.57527824.mjs (mode: 'all')
import nuxt_plugin_compositionapi_a8f56b68 from 'nuxt_plugin_compositionapi_a8f56b68' // Source: ../plugins/composition-api.ts (mode: 'all')
import nuxt_plugin_customconfiguration_680488c8 from 'nuxt_plugin_customconfiguration_680488c8' // Source: ../plugins/custom-configuration.ts (mode: 'all')
import nuxt_plugin_indexclient_58e08a5f from 'nuxt_plugin_indexclient_58e08a5f' // Source: ../plugins/logger/index-client.ts (mode: 'client')
import nuxt_plugin_indexserver_82cc0832 from 'nuxt_plugin_indexserver_82cc0832' // Source: ../plugins/logger/index-server.ts (mode: 'server')
import nuxt_plugin_configbylocale_072b0411 from 'nuxt_plugin_configbylocale_072b0411' // Source: ../plugins/config-by-locale.ts (mode: 'all')
import nuxt_plugin_vuelidate_7b59eb33 from 'nuxt_plugin_vuelidate_7b59eb33' // Source: ../plugins/vuelidate.ts (mode: 'all')
import nuxt_plugin_composablestorage_00fc5fca from 'nuxt_plugin_composablestorage_00fc5fca' // Source: ../plugins/composable-storage.ts (mode: 'all')
import nuxt_plugin_preview_4460e846 from 'nuxt_plugin_preview_4460e846' // Source: ../plugins/preview.js (mode: 'all')
import nuxt_plugin_api_78da052c from 'nuxt_plugin_api_78da052c' // Source: ../plugins/api.ts (mode: 'all')
import nuxt_plugin_apiclientlocale_d564768a from 'nuxt_plugin_apiclientlocale_d564768a' // Source: ../plugins/api-client-locale.ts (mode: 'all')
import nuxt_plugin_cms_72521ab6 from 'nuxt_plugin_cms_72521ab6' // Source: ../plugins/cms.js (mode: 'all')
import nuxt_plugin_storeFilter_73f2572a from 'nuxt_plugin_storeFilter_73f2572a' // Source: ../plugins/storeFilter.ts (mode: 'client')
import nuxt_plugin_linkgenerators_109ac29e from 'nuxt_plugin_linkgenerators_109ac29e' // Source: ../plugins/link-generators.ts (mode: 'all')
import nuxt_plugin_customscaid_7f71b39c from 'nuxt_plugin_customscaid_7f71b39c' // Source: ../plugins/customs-caid.ts (mode: 'all')
import nuxt_plugin_uimanager_5de636c2 from 'nuxt_plugin_uimanager_5de636c2' // Source: ../plugins/ui-manager.ts (mode: 'all')
import nuxt_plugin_viewportobserver_d843ef72 from 'nuxt_plugin_viewportobserver_d843ef72' // Source: ../plugins/viewport-observer.ts (mode: 'all')
import nuxt_plugin_vuezoomer_0c67b78e from 'nuxt_plugin_vuezoomer_0c67b78e' // Source: ../plugins/vue-zoomer.js (mode: 'client')
import nuxt_plugin_employeesessionclient_65e4d1e2 from 'nuxt_plugin_employeesessionclient_65e4d1e2' // Source: ../plugins/employee-session.client.ts (mode: 'client')
import nuxt_plugin_locationconfirmation_08eca0d2 from 'nuxt_plugin_locationconfirmation_08eca0d2' // Source: ../plugins/location-confirmation.ts (mode: 'client')
import nuxt_plugin_gtm_63eb7eb8 from 'nuxt_plugin_gtm_63eb7eb8' // Source: ../plugins/gtm.ts (mode: 'client')
import nuxt_plugin_trackroutingclient_2ed4a2be from 'nuxt_plugin_trackroutingclient_2ed4a2be' // Source: ../plugins/track-routing.client.ts (mode: 'client')
import nuxt_plugin_customsvuecomponents_4d81d742 from 'nuxt_plugin_customsvuecomponents_4d81d742' // Source: ../plugins/customs-vue-components (mode: 'client')
import nuxt_plugin_iframepluginclient_0ff6364e from 'nuxt_plugin_iframepluginclient_0ff6364e' // Source: ../plugins/iframe-plugin.client.ts (mode: 'client')
import nuxt_plugin_axe_777431c2 from 'nuxt_plugin_axe_777431c2' // Source: ../plugins/axe.js (mode: 'client')
import nuxt_plugin_vuerecaptcha_961ce57a from 'nuxt_plugin_vuerecaptcha_961ce57a' // Source: ../plugins/vue-recaptcha.ts (mode: 'client')
import nuxt_plugin_themeconfig_250cd08c from 'nuxt_plugin_themeconfig_250cd08c' // Source: ../plugins/theme-config.js (mode: 'all')
import nuxt_plugin_themesettings_6fe2a9f9 from 'nuxt_plugin_themesettings_6fe2a9f9' // Source: ../plugins/theme-settings.js (mode: 'all')
import nuxt_plugin_apiperformancetracker_a299ee48 from 'nuxt_plugin_apiperformancetracker_a299ee48' // Source: ../plugins/api-performance-tracker.ts (mode: 'all')
import nuxt_plugin_statemanagement_58511e97 from 'nuxt_plugin_statemanagement_58511e97' // Source: ../plugins/state-management.ts (mode: 'all')
import nuxt_plugin_customersegments_abfc4b16 from 'nuxt_plugin_customersegments_abfc4b16' // Source: ../plugins/customer-segments.ts (mode: 'all')
import nuxt_plugin_domainname_487cef60 from 'nuxt_plugin_domainname_487cef60' // Source: ../plugins/domain-name.ts (mode: 'all')
import nuxt_plugin_priceformatter_2ede703e from 'nuxt_plugin_priceformatter_2ede703e' // Source: ../plugins/price-formatter.ts (mode: 'all')
import nuxt_plugin_pageurl_069d0025 from 'nuxt_plugin_pageurl_069d0025' // Source: ../plugins/page-url.ts (mode: 'all')
import nuxt_plugin_sessionclient_4a7b399a from 'nuxt_plugin_sessionclient_4a7b399a' // Source: ../plugins/session.client.ts (mode: 'client')
import nuxt_plugin_socialnativeclient_58536f14 from 'nuxt_plugin_socialnativeclient_58536f14' // Source: ../plugins/social-native.client.ts (mode: 'client')
import nuxt_plugin_jsonld_469038d6 from 'nuxt_plugin_jsonld_469038d6' // Source: ../plugins/jsonld.ts (mode: 'all')
import nuxt_plugin_storelocator_873315cc from 'nuxt_plugin_storelocator_873315cc' // Source: ../plugins/store-locator.ts (mode: 'all')
import nuxt_plugin_vuescreen_c32dc57a from 'nuxt_plugin_vuescreen_c32dc57a' // Source: ../plugins/vue-screen.ts (mode: 'all')
import nuxt_plugin_imagespreconnect_32ceaac7 from 'nuxt_plugin_imagespreconnect_32ceaac7' // Source: ../plugins/images-preconnect.ts (mode: 'all')
import nuxt_plugin_circuitbreakerserver_1421a824 from 'nuxt_plugin_circuitbreakerserver_1421a824' // Source: ../plugins/circuit-breaker.server.ts (mode: 'server')
import nuxt_plugin_launchdarkly_c886bab4 from 'nuxt_plugin_launchdarkly_c886bab4' // Source: ../plugins/launch-darkly.ts (mode: 'all')
import nuxt_plugin_supportedcreditcards_501be369 from 'nuxt_plugin_supportedcreditcards_501be369' // Source: ../plugins/supported-credit-cards.ts (mode: 'all')
import nuxt_plugin_vclipboard_8d1c5278 from 'nuxt_plugin_vclipboard_8d1c5278' // Source: ../plugins/v-clipboard.ts (mode: 'client')
import nuxt_plugin_promotiontracker_1e6ff8d5 from 'nuxt_plugin_promotiontracker_1e6ff8d5' // Source: ../plugins/promotion-tracker.ts (mode: 'client')
import nuxt_plugin_estimateddeliverydate_0abe2d66 from 'nuxt_plugin_estimateddeliverydate_0abe2d66' // Source: ../plugins/estimated-delivery-date.ts (mode: 'client')
import nuxt_plugin_forterclient_02da017c from 'nuxt_plugin_forterclient_02da017c' // Source: ../plugins/forter.client.ts (mode: 'client')
import nuxt_plugin_meta_5f3262f2 from 'nuxt_plugin_meta_5f3262f2' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Official Site","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Fthenorthface\u002Ffavicon.ico"}],"script":[{"type":"text\u002Fjavascript","innerHTML":"var monetateT = new Date().getTime();"},{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);}})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"style":[],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":""}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_573e30f8 === 'function') {
    await nuxt_plugin_plugin_573e30f8(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_50c80fe0 === 'function') {
    await nuxt_plugin_plugin_50c80fe0(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_11babbed === 'function') {
    await nuxt_plugin_pluginutils_11babbed(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_43fc01f8 === 'function') {
    await nuxt_plugin_pluginrouting_43fc01f8(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7b04ec77 === 'function') {
    await nuxt_plugin_pluginmain_7b04ec77(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtenv_726e3a87 === 'function') {
    await nuxt_plugin_nuxtenv_726e3a87(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_65968cac === 'function') {
    await nuxt_plugin_workbox_65968cac(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_0436b6bd === 'function') {
    await nuxt_plugin_metaplugin_0436b6bd(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_08698264 === 'function') {
    await nuxt_plugin_gtm_08698264(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_15afdfe4 === 'function') {
    await nuxt_plugin_axios_15afdfe4(app.context, inject)
  }

  if (typeof nuxt_plugin_stringprototypereplaceall_73e5df84 === 'function') {
    await nuxt_plugin_stringprototypereplaceall_73e5df84(app.context, inject)
  }

  if (typeof nuxt_plugin_smoothscrollpolyfill_1951eb79 === 'function') {
    await nuxt_plugin_smoothscrollpolyfill_1951eb79(app.context, inject)
  }

  if (typeof nuxt_plugin_intersectionobserver_b7e87bc0 === 'function') {
    await nuxt_plugin_intersectionobserver_b7e87bc0(app.context, inject)
  }

  if (typeof nuxt_plugin_resizeobserver_69bc4a0b === 'function') {
    await nuxt_plugin_resizeobserver_69bc4a0b(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin57527824_68708b8a === 'function') {
    await nuxt_plugin_distplugin57527824_68708b8a(app.context, inject)
  }

  if (typeof nuxt_plugin_compositionapi_a8f56b68 === 'function') {
    await nuxt_plugin_compositionapi_a8f56b68(app.context, inject)
  }

  if (typeof nuxt_plugin_customconfiguration_680488c8 === 'function') {
    await nuxt_plugin_customconfiguration_680488c8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_indexclient_58e08a5f === 'function') {
    await nuxt_plugin_indexclient_58e08a5f(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_indexserver_82cc0832 === 'function') {
    await nuxt_plugin_indexserver_82cc0832(app.context, inject)
  }

  if (typeof nuxt_plugin_configbylocale_072b0411 === 'function') {
    await nuxt_plugin_configbylocale_072b0411(app.context, inject)
  }

  if (typeof nuxt_plugin_vuelidate_7b59eb33 === 'function') {
    await nuxt_plugin_vuelidate_7b59eb33(app.context, inject)
  }

  if (typeof nuxt_plugin_composablestorage_00fc5fca === 'function') {
    await nuxt_plugin_composablestorage_00fc5fca(app.context, inject)
  }

  if (typeof nuxt_plugin_preview_4460e846 === 'function') {
    await nuxt_plugin_preview_4460e846(app.context, inject)
  }

  if (typeof nuxt_plugin_api_78da052c === 'function') {
    await nuxt_plugin_api_78da052c(app.context, inject)
  }

  if (typeof nuxt_plugin_apiclientlocale_d564768a === 'function') {
    await nuxt_plugin_apiclientlocale_d564768a(app.context, inject)
  }

  if (typeof nuxt_plugin_cms_72521ab6 === 'function') {
    await nuxt_plugin_cms_72521ab6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_storeFilter_73f2572a === 'function') {
    await nuxt_plugin_storeFilter_73f2572a(app.context, inject)
  }

  if (typeof nuxt_plugin_linkgenerators_109ac29e === 'function') {
    await nuxt_plugin_linkgenerators_109ac29e(app.context, inject)
  }

  if (typeof nuxt_plugin_customscaid_7f71b39c === 'function') {
    await nuxt_plugin_customscaid_7f71b39c(app.context, inject)
  }

  if (typeof nuxt_plugin_uimanager_5de636c2 === 'function') {
    await nuxt_plugin_uimanager_5de636c2(app.context, inject)
  }

  if (typeof nuxt_plugin_viewportobserver_d843ef72 === 'function') {
    await nuxt_plugin_viewportobserver_d843ef72(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuezoomer_0c67b78e === 'function') {
    await nuxt_plugin_vuezoomer_0c67b78e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_employeesessionclient_65e4d1e2 === 'function') {
    await nuxt_plugin_employeesessionclient_65e4d1e2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_locationconfirmation_08eca0d2 === 'function') {
    await nuxt_plugin_locationconfirmation_08eca0d2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtm_63eb7eb8 === 'function') {
    await nuxt_plugin_gtm_63eb7eb8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_trackroutingclient_2ed4a2be === 'function') {
    await nuxt_plugin_trackroutingclient_2ed4a2be(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_customsvuecomponents_4d81d742 === 'function') {
    await nuxt_plugin_customsvuecomponents_4d81d742(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_iframepluginclient_0ff6364e === 'function') {
    await nuxt_plugin_iframepluginclient_0ff6364e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_axe_777431c2 === 'function') {
    await nuxt_plugin_axe_777431c2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuerecaptcha_961ce57a === 'function') {
    await nuxt_plugin_vuerecaptcha_961ce57a(app.context, inject)
  }

  if (typeof nuxt_plugin_themeconfig_250cd08c === 'function') {
    await nuxt_plugin_themeconfig_250cd08c(app.context, inject)
  }

  if (typeof nuxt_plugin_themesettings_6fe2a9f9 === 'function') {
    await nuxt_plugin_themesettings_6fe2a9f9(app.context, inject)
  }

  if (typeof nuxt_plugin_apiperformancetracker_a299ee48 === 'function') {
    await nuxt_plugin_apiperformancetracker_a299ee48(app.context, inject)
  }

  if (typeof nuxt_plugin_statemanagement_58511e97 === 'function') {
    await nuxt_plugin_statemanagement_58511e97(app.context, inject)
  }

  if (typeof nuxt_plugin_customersegments_abfc4b16 === 'function') {
    await nuxt_plugin_customersegments_abfc4b16(app.context, inject)
  }

  if (typeof nuxt_plugin_domainname_487cef60 === 'function') {
    await nuxt_plugin_domainname_487cef60(app.context, inject)
  }

  if (typeof nuxt_plugin_priceformatter_2ede703e === 'function') {
    await nuxt_plugin_priceformatter_2ede703e(app.context, inject)
  }

  if (typeof nuxt_plugin_pageurl_069d0025 === 'function') {
    await nuxt_plugin_pageurl_069d0025(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sessionclient_4a7b399a === 'function') {
    await nuxt_plugin_sessionclient_4a7b399a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_socialnativeclient_58536f14 === 'function') {
    await nuxt_plugin_socialnativeclient_58536f14(app.context, inject)
  }

  if (typeof nuxt_plugin_jsonld_469038d6 === 'function') {
    await nuxt_plugin_jsonld_469038d6(app.context, inject)
  }

  if (typeof nuxt_plugin_storelocator_873315cc === 'function') {
    await nuxt_plugin_storelocator_873315cc(app.context, inject)
  }

  if (typeof nuxt_plugin_vuescreen_c32dc57a === 'function') {
    await nuxt_plugin_vuescreen_c32dc57a(app.context, inject)
  }

  if (typeof nuxt_plugin_imagespreconnect_32ceaac7 === 'function') {
    await nuxt_plugin_imagespreconnect_32ceaac7(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_circuitbreakerserver_1421a824 === 'function') {
    await nuxt_plugin_circuitbreakerserver_1421a824(app.context, inject)
  }

  if (typeof nuxt_plugin_launchdarkly_c886bab4 === 'function') {
    await nuxt_plugin_launchdarkly_c886bab4(app.context, inject)
  }

  if (typeof nuxt_plugin_supportedcreditcards_501be369 === 'function') {
    await nuxt_plugin_supportedcreditcards_501be369(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vclipboard_8d1c5278 === 'function') {
    await nuxt_plugin_vclipboard_8d1c5278(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_promotiontracker_1e6ff8d5 === 'function') {
    await nuxt_plugin_promotiontracker_1e6ff8d5(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_estimateddeliverydate_0abe2d66 === 'function') {
    await nuxt_plugin_estimateddeliverydate_0abe2d66(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_forterclient_02da017c === 'function') {
    await nuxt_plugin_forterclient_02da017c(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_5f3262f2 === 'function') {
    await nuxt_plugin_meta_5f3262f2(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
